/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
$primary: #ff5b4a;
$primary-focus: rgba(242, 73, 56, 0.2);
$secondary: #2d1855;
$default: #0e1029;

html {
	overflow-y: scroll;
	overflow-x: hidden;
}
body {
	// padding-right: 15px;
}
/**
	Button
*/
.btn {
	border-radius: 16px;
	&:not(&.form-button) {
		border-radius: 16px;
	}
	&:disabled {
		background-color: $default !important;
		border-color: $default !important;
	}
	&.btn-primary {
		&:hover {
			background-color: $primary !important;
			box-shadow: 0 4px 14px  $primary !important;
		}
	}
}


/**
	Input
*/
[dir] .form-control {
	// border-radius: 12px;
}

.transition-color {
	transition: color 0.2s;
}
.indicator::after {
	content: '';
    margin: 0 100%;
	margin-top: 9px !important;
    height: 4px;
    background: var(--bs-primary);
    display: block;
    border-radius: 4px;
	transition: margin 0.2s;
}
.indicator.text-primary::after {
	margin: auto;
	width: 12px;
}

.flatpickr-calendar {
	background-color: '#282932' !important;
}

.dark-layout .select__control {
	background-color: rgb(40, 41, 50) !important;
}

.experience-cicle::after, .education-cicle::after {
	content: '';
    height: 60px;
    width: 1px;
    position: absolute;
    border-right: dashed 1px var(--bs-primary);
    transform: translateY(113%);
	@media (max-width: 767px) {
		content: "";
		height: 194px;
		width: 0px;
		position: absolute;
		border-right: dashed 1px var(--bs-primary);
		transform: translateY(65%);
	}
}
.education-cicle::after {
    transform: translateY(150%);
	@media (max-width: 767px) {
		transform: translateY(65%);
		height: 224px;
	}
}

.file-upload-custon {
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='48' ry='48' stroke='rgb(186, 191, 199)' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='55' stroke-linecap='square'/%3e%3c/svg%3e");
	border-radius: 48px;
}

.file-upload-custon.error {
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='48' ry='48' stroke='rgb(255, 91, 74)' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='55' stroke-linecap='square'/%3e%3c/svg%3e");
	border-radius: 48px;
}

.CircularProgressbar {
	position: absolute;
}
.CircularProgressbar.challenge-card  {
	width: 60px;
	height: 60px;
	right: 16px;
	top: 16px;
}
.cicle-progress-line::before {
	content: " ";
    height: 350px;
    width: 0;
    border-left: dashed 2px black;
    z-index: -1;
    transform: translateY(-50%);
	@media (max-width: 767px) {
		border-width: 1px;
	}
}

.skeleton-loading {
	animation: opacity-wave 1.5s infinite ease-out;
}

@keyframes opacity-wave {
    0% {
        opacity: .1;
    }
	50% {
		opacity: .8;
	}
     100% {
        opacity: .1;
    }
}

.select__control {
	border-radius: 16px !important;
}
.text-ellipisis-2 {
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
}

.challenge-card{
	height: 422px;
	color: #03030C;
	.challenge-card-header {
		position: relative;
		background-color: #FFF1E2;
		justify-content: center;
		height: 160px;
	}
	.join-btn {
		position: absolute;
		width: 57px;
		height: 57px;
		border-radius: 50% !important; 
		right: 60px; 
		bottom: -25px;
		svg {
			position:absolute;
			top: 18px;
			right: 18px;
		}
		@media (max-width: 767px) {
			width: 40px;
			height: 40px;
			svg {
				top: 10px;
				right: 10px;
			}
		}
	}
	@media (max-width: 767px) {
		height: unset;
	}
}
//  HR QUESTION
.multiple-choice-question, .multiple-choice-image-question, 
.checkbox-question, .file-upload-question {
    padding-top: 40px;
	padding-left: 40px;
	padding-right: 40px;
	@media (max-width: 544px) {
		padding-left: 24px;
		padding-right: 24px;
	}
	.question-no {
		width: 60px;
		height: 60px;
		background: #FF5B4A;
		border-radius: 20px;
		position: absolute;
		top: 56px;
		left: -30px;
		display: flex;
		justify-content: center;
		align-items: center;
		@media (max-width: 768px) {
			top: -30px;
			left: 24px;
		}
	}
	.multiple-choice-list, .multiple-check-list {
		background-color: #F5F5F5;
		border-radius: 16px;
		padding-left: 24px;
		padding-top: 8px;
		padding-bottom: 8px;
		margin-bottom: 16px;
		label {
			position: relative;
			display: flex;
			align-items: center;
			cursor: pointer;
		}
	}
	.multiple-check-list {
		background-color: #FFF;
		border-radius: 16px;
		padding-left: 24px;
		padding-top: 8px;
		padding-bottom: 8px;
		margin-bottom: 16px;
		border:1px solid rgba(30, 30, 30, 0.12);
		.checkBox-form {
			display: flex;
			align-items: center;
		}
		label {
			position: relative;
			display: flex;
			align-items: center;
			cursor: pointer;
		}
	}
	.multiple-check-list.isChecked {
		border: 1px solid #FF5B4A;
		
		.form-check-input {
			background-color: #FF5B4A !important;
		}
	}
	.image-in-multiple-choice.isChecked {
		outline: 3px solid #FF5B4A;
	}
	.multiple-choice-list.isChecked,  .multiple-choice-list.isChecked:hover{
		background-color: #FF5B4A;
		color: #FDFDFD;
	}
	.multiple-choice-list:hover, .multiple-check-list:hover {
		cursor: pointer;
		background-color: rgba(255, 91, 74, 0.12);
		.form-check-input {
			background-color: rgba(255, 91, 74, 0);
		}
	}
	
	.ifChecked {
		position: absolute;
	}
	
	.multiple-choice-list .custom-radio-btn {
		width: 28px;
		min-width: 28px;
		height: 28px;
		border-radius: 15px;
		border: 2px solid #fff;
		background-color: white;
		-webkit-appearance: none;
		-moz-appearance: none;
	  }
	  
	  .multiple-choice-list .custom-radio-btn:focus {
		outline: none;
	  }
	  
	  .multiple-choice-list .custom-radio-btn:checked {
		background-color: #fff;
	  }
	
	  .multiple-choice-list .custom-radio-btn:checked::after {
		  background-color: #FF5B4A;
	  }
	  
	  .multiple-choice-list .custom-radio-btn:checked ~ span:first-of-type {
		visibility: hidden;
	  }
	
	  .multiple-choice-list .custom-radio-btn:checked ~ div.ifChecked:first-of-type{
		  background-color: #FF5B4A;
		  width: 16px;
		  height: 16px;
		  min-width: 16px;
		  min-height: 16px;
		  left: 6px;
		  border-radius: 50%;
	  }
	  
	  .multiple-choice-list span:first-of-type {
		position: relative;
		left: -20px;
		font-size: 16px;
		color: #03030C;
	  }
}

.list-of-answer {
	min-height: 100px;
	background-color: rgba(255, 91, 74, 0.12);
	border-radius: 24px;
	padding: 16px 24px;
	margin-bottom: 16px;
}

.rating-scale-section{
	.rating-scale {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 1;
		margin-right: 80px;
		max-width: 80px;
		.active-radio {
			width: 40px;
			height: 40px;
			border: 1px dashed #03030C;
			border-radius: 50%;
			top:-7px;
			justify-content: center;
			align-items: center;
			background-color: #fff;
			display: none;
			position: absolute;
		}
		.active-radio.isActive {
			display: flex;
		}
		@media (max-width: 544px) {
			margin-right: 40px;			
		}
	}
	.rating-scale:last-child{
			margin-right: 0px;
	}
}

.rating-scale input[type='radio'] {
	width: 24px;
	height: 24px;
}

.rating-scale input[type='radio']:hover::after {
	background-color: rgba(255, 91, 74, 0.12);
}

.rating-scale input[type='radio']::after {
	width: 24px;
	height: 24px;
	border-radius: 15px;
	position: relative;
	content: '';
	display: inline-block;
	visibility: visible;
	border: 1px solid #FF5B4A;
	background-color: #fff;
	top: -1px;
	left: -1px;
	cursor: pointer;
}

.cooldown-counter {
	display: flex;
	position: absolute;
	top:0px;
	right: 0px;
	left: 0px;
	bottom: 0px;
	justify-content: center;
	align-items: center;
	.modal-content{
		background: unset;
		box-shadow: unset;
		.cooldown-text{
			font-size: 240px;
			font-weight: 600;
			line-height: 293px;
			font-style: italic;
			color:#fff;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
// END HR QUESTION
.img-grid img{
    max-width: 700px;
}
.file-upload-question img {
	max-width: 700px;
}