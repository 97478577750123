.check-mark {
    margin-top: 24px;
}
@media only screen and (max-width: 600px) {
    p {
        word-wrap: break-word;
    }
    .mob-bar{
        margin: 0 auto;
        padding: 10px;
        width: 75%;
        word-wrap: break-word;
    }
    .m_size_p{
        padding-right: 300px;
    }

    .text-white{
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
    }
    .img-mob{
        padding-right: 300px;
    }

    .tab-content{
        padding-bottom:100px;
    }

    .text-hide{
        font-size: 0 !important;
    }
    .mob-icon{
        display: flex;
        justify-content: center;
    }

    .mob-no{
        margin-top: 30px;

    }

    .check-mark{
        position: absolute;
        margin-top: 80px;
        // background-color: orange;
    }
}